import { Link, navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import React, { useContext, useState } from 'react'
import Layout from '../components/layout';
import SEO from '../components/seo';
import ShopContext from '../context/ShopContext';
import axios from 'axios';


const sanityConfig = {projectId: 'a3n0vinp', dataset: 'production'}


const Handlekurv = () => {

    const context = useContext(ShopContext);



    const [formState, setFormState] = useState({
        name: "",
        email: "",
        terms: false
    })

    function mergeFigures(figures) {
        return figures.map(x => `${x.name} v${x.version} ${x.year} (id: ${x.id})`).join(", ")
    }
  
    const submit = e => {
        e.preventDefault();

        const {name, email, terms} = formState

        axios({
            url: 'https://formspree.io/f/mzboleep',
            method: 'post',
            headers: {
              'Accept': 'application/json'
            },
            data: {
              name,
              email,
              terms,
              figures: mergeFigures(context.cart)
            }
          }).then((response) => { 
            console.log(response); 
            context.clearCart()
            navigate("/bestilling-mottatt")  

        })
                  

        /*

        fetch('/', {
			method: 'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: encode({
				'form-name': 'bestilling',
				'name': formState.name,
				'email': formState.email,
                'figures': mergeFigures(context.cart)
			})

		})
        .then(response => {
        })
        */

    }

    
    return (<>
        <Layout>
            <SEO title="Handlekurv" />
            <div className="container">
                <div className="sm:p-4 md:p-8 lg:p-16 ">
                    <h1 className="mb-5">Din handlekurv</h1>
                    <div className="flex flex-col lg:flex-row">
                    {context.cart.length > 0 
                    ? (<>

                        <table className=" w-full text-sm lg:text-base 2xl:w-8/12" cellSpacing="0">
                            <thead>
                                <tr className="h-16">
                                    <th className="mr-6 text-left">
                                        
                                    </th>
                                    <th className="text-left">
                                        Figur
                                    </th>
                                    <th className="text-left">
                                        Pris
                                    </th>
                                </tr>
                            </thead>                 
                            <tbody>
                                {context.cart.map(product => {
                                    const imageData = product.type !== "gijoeio" ? getGatsbyImageData(product.image.asset, {maxWidth: 125}, sanityConfig) : null

                                    return (
                                    <tr key={product.id}>
                                        <td className="w-28 p-5">
                                        
                                            {product.type !== "gijoeio" 
                                                ? <GatsbyImage image={imageData} alt="product image"/>
                                                : <img alt="bilde av figur" src={product.image} />
                                            }
                                        </td>
                                        <td>
                                            <Link className="hover:underline" to={`/${product.slug.current}`}>{product.name}</Link><br/>
                                            <span className="text-gray-700">v{product.version} - {product.year}</span><br/><span className="text-sm cursor-pointer hover:underline text-gray-500" tabIndex={0} role="button" onKeyDown={context.removeProductFromCart.bind(this, product.id)} onClick={context.removeProductFromCart.bind(this, product.id)}>Slett produkt</span>
                                            </td>
                                        <td>kr {product.price}</td>
                                    </tr>
                                )})}
                                    <tr>
                                        <td></td>
                                        <td className="font-semibold">Totalt</td>
                                        <td className="font-semibold">kr {context.cart.reduce((prev, cur) => prev + parseInt(cur.price), 0)}</td>
                                    </tr>
                                
                            </tbody>
                        </table>
                        </>)
                        : <p className="mt-5">Handlekurven er tom</p> }



                        <div className={context.cart.length > 0 ? "mt-10 flex flex-row justify-items-start w-full 2xl:w-1/2" : "hidden"}>
                            <div className="flex-grow flex">
                                <form name="bestilling" className="flex-grow" onSubmit={submit}>
                                    <input type="hidden" name="form-name" value="bestilling" />

                                    <h2>Send bestilling</h2>
                                    <p className="mt-2 mb-3 text-sm text-gray-500">
                                        Alle bestillinger blir behandlet manuelt.
                                        Legg igjen ditt navn og epostadresse så vil jeg kontakte deg.<br/>
                                        Frakt kommer i tillegg på alle priser.
                                    </p>

                                    <div className="mb-6 flex-grow">
                                        <label htmlFor="name" className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Navn</label>
                                        <input onChange={(e) => setFormState({...formState, name: e.target.value})} type="text" name="name" id="name" placeholder="Ditt navn" required className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Epost</label>
                                        <input onChange={(e) => setFormState({...formState, email: e.target.value})} type="email" name="email" id="email" placeholder="Din epostadresse" required className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                    </div>
                                    <div className="mb-6 text-sm">
                                        
                                        <input name="terms" required id="terms" type="checkbox" className="mr-3" onChange={(e) => setFormState({...formState, terms: e.target.checked})}/>
                                        <label htmlFor="terms" >Jeg har lest og godtatt betingelsene</label>
                                    </div>
                                    <div className="mb-6">
                                        <button type="submit" className="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none">Send bestilling</button>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



        
        </Layout>

    </>)

}

export default Handlekurv